<template>
	<div class="home">
		<div class="head">
			<img src="@/assets/images/menu.png" alt="" class="menu" @click="handleClickMenu()" />
			<img src="@/assets/images/logo.svg" alt="" class="ripe" />
		</div>
		<div class="content">
			<div class="userInfo">
				<div class="level">{{ userInfo.vipLevel }}</div>
				<div class="welcome">Hi,Welcome</div>
				<div class="name">{{ userInfo.userName }}</div>
			</div>
			<div class="tipsBox">
				<img src="@/assets/images/lingdang.png" alt="" />
				<p>
					<marquee><span style="font-size: 12px">Welcome to RevenueLab</span></marquee>
				</p>
			</div>
			<div class="buttonBox">
				<div class="itemBox" @click="goStaring()">
					<div class="imgBox">
						<img src="@/assets/images/home_button1.png" alt="" />
					</div>
					<p>Starting</p>
				</div>
				<div class="itemBox" @click="goPage('/cert')">
					<div class="imgBox">
						<img src="@/assets/images/home_button2.png" alt="" />
					</div>
					<p>Certificate</p>
				</div>
				<div class="itemBox" @click="goPage('/withdraw')">
					<div class="imgBox">
						<img src="@/assets/images/home_button3.png" alt="" />
					</div>
					<p>Withdraw</p>
				</div>
				<div class="itemBox" @click="goPage('/deposit')">
					<div class="imgBox">
						<img src="@/assets/images/home_button4.png" alt="" />
					</div>
					<p>Deposit</p>
				</div>
			</div>
			<div class="levelBox">
				<div class="bg">
					<h2>
						<span>VIP Level</span>
						<a href="javascript:;" @click="goPage('/vipLevel')">View More ></a>
					</h2>
					<div class="level">
						<div :class="['item', userInfo.vipLevel === '1' ? 'on' : '']">
							<img src="@/assets/images/level1.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
						<div :class="['item', userInfo.vipLevel === '2' ? 'on' : '']">
							<img src="@/assets/images/level2.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
						<div :class="['item', userInfo.vipLevel === '3' ? 'on' : '']">
							<img src="@/assets/images/level3.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
						<div :class="['item', userInfo.vipLevel === '4' ? 'on' : '']">
							<img src="@/assets/images/level4.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
					</div>
				</div>
				<div class="textBox">
					<h3>{{ vipInfo.vipName }}</h3>
					<p>VIP {{ vipInfo.vipLevelId }} Optimization App</p>
					<ul>
						<li>Receive a set of {{ vipInfo.taskCount }} apps data tasks</li>
						<li>Profit of {{ vipInfo.pointReward }}% per application</li>
						<li>Activate with a {{ vipInfo.vipPrice }} USDT</li>
					</ul>
				</div>
			</div>
			<div class="otherBox">
				<div class="item" @click="goPage('/tc')">
					<img src="@/assets/images/sm1.png" alt="" />
					<p>T&C</p>
				</div>
				<div class="item" @click="goPage('/event')">
					<img src="@/assets/images/sm2.png" alt="" />
					<p>Event</p>
				</div>
				<div class="item" @click="goPage('/faq')">
					<img src="@/assets/images/sm3.png" alt="" />
					<p>FAQ</p>
				</div>
				<div class="item" @click="goPage('/about')">
					<img src="@/assets/images/sm4.png" alt="" />
					<p>About Us</p>
				</div>
			</div>
		</div>
		<Slide ref="slide" />
		<van-overlay :show="showOverlay" z-index="1000">
			<div class="notice">
				<div class="imgBox">
					<img src="@/assets/images/close.png" class="closeImg" @click="closeNotice()" alt="" />
					<img v-if="noticeInfo.imgUrl" :src="noticeInfo.imgUrl" alt="" class="noticeImg" />
					<div class="noticeText" v-html="noticeInfo.body" v-if="!noticeInfo.imgUrl && noticeInfo.body"></div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Slide from "@/components/slide.vue";
	export default {
		components: {
			Slide
		},
		data() {
			return {
				showOverlay: false,
				noticeInfo: {
					body: "",
					imgUrl: "",
				},
				userInfo: {},
				vipData: [],
				vipInfo: {},
			};
		},
		methods: {
			handleClickMenu() {
				sessionStorage.setItem('slide', true);
				this.$refs.slide.showSlide = true;
				this.$refs.slide.initPage();
			},
			goPage(page) {
				this.$router.push(page);
			},
			goStaring() {
				this.$emit("goStaring");
			},
			closeNotice() {
				localStorage.removeItem('firstLogin');
				this.showOverlay = false;
			},
		},
		mounted() {
			// 检查是否是第一次登录
			const isFirstLogin = localStorage.getItem("firstLogin");
			if (isFirstLogin) {
				// 如果是第一次登录，获取公告详情
				this.getAjax("api/ahsuoapiemla/bbshwuzma/notice", {}, "get").then((res) => {
					this.noticeInfo = res.data;
					if (res.data.body || res.data.imgUrl) {
						this.showOverlay = true; // 根据获取到的数据决定是否显示覆盖层
					}
				});
			}
			this.getAjax("api/zopuwmbfapsnb/bbywisnzjawm/getBalance", {}, "get").then((res) => {
				this.userInfo = res.data;
				this.getAjax("api/visppxnzjsurbaks/yuensmhzwgq/page", {}, "get").then((res) => {
					this.vipData = res.data.list;
					this.vipInfo = this.vipData[this.userInfo.vipLevel - 1];
				});
			});
		},
	};
</script>

<style scoped lang="less">
	.home {
		padding: 90px 0;

		.notice {
			position: relative;
			width: 90%;
			left: 5%;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 80%;
			top: 10%;

			.imgBox {
				position: relative;
				height: 100%;

				.noticeImg {
					width: 100%;
					max-height: 100%;
				}

				.noticeText {
					min-height: 400px;
					background: #fff;
					padding: 40px 15px 15px;
					box-sizing: border-box;
					word-wrap: break-word;
					word-break: break-all;
				}

				.closeImg {
					width: 25px;
					height: 25px;
					position: absolute;
					right: 5px;
					top: 5px;
				}
			}
		}

		.head {
			width: 100%;
			height: 90px;
			background: url("@/assets/images/nav_bg.png") 0 -380px no-repeat;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;

			.menu {
				width: 28px;
				position: absolute;
				top: 10px;
				left: 10px;
			}

			.ripe {
				width: 150px;
				margin: 15px auto;
				display: block;
			}
		}

		.content {
			.userInfo {
				margin-top: -30px;

				.level {
					width: 50px;
					height: 50px;
					background: url("@/assets/images/mylevel.png") no-repeat;
					background-size: cover;
					margin: 0 auto;
					color: #fff;
					text-align: center;
					line-height: 50px;
					font-weight: bold;
				}

				.welcome {
					font-size: 20px;
					text-align: center;
					font-weight: bold;
					color: rgba(40, 159, 201, 1);
					line-height: 1;
				}

				.name {
					font-size: 16px;
					text-align: center;
					color: #2cbbc1;
				}
			}

			.tipsBox {
				width: 100%;
				height: 32px;
				display: flex;
				align-items: center;
				padding: 0 20px;
				background: url("@/assets/images/tarbar_bg.png");
				margin-top: 15px;

				img {
					width: 20px;
					margin-right: 20px;
				}

				p {
					flex: 1;
					color: #fff;
				}
			}

			.buttonBox {
				display: flex;

				.itemBox {
					margin-top: 15px;
					flex: 1;

					.imgBox {
						width: 64px;
						height: 67px;
						background: url("@/assets/images/button_bg.png") no-repeat;
						background-size: cover;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;

						img {
							width: 80%;
						}
					}

					p {
						text-align: center;
						font-size: 13px;
						color: #299E89;
						margin-top: 5px;
					}
				}
			}

			.levelBox {
				border: 2px solid #35dfc0;
				margin: 10px;
				border-radius: 10px;
				overflow: hidden;

				.bg {
					height: 110px;
					background-image: linear-gradient(to bottom, #1acbb4, #22b2c0);
					position: relative;

					h2 {
						height: 30px;
						display: flex;
						align-items: center;
						padding: 0 10px;

						span {
							font-size: 14px;
							flex: 1;
							color: #fff;
						}

						a {
							font-size: 12px;
						}
					}

					.level {
						display: flex;
						bottom: 0px;
						position: absolute;

						.item {
							height: 70px;
							flex: 1;
							position: relative;
							margin: 0 10px;

							.lv {
								width: 65px;
								display: block;
								margin: 10px auto 0;
							}

							.lock {
								width: 20px;
								position: absolute;
								right: 10px;
								bottom: 5px;
							}
						}

						.on {
							border: 2px solid #36dfc8;
							border-bottom: 2px solid #fff;
							background: #fff;
							border-top-left-radius: 20px;
							border-top-right-radius: 20px;

							.lock {
								display: none;
							}
						}
					}
				}
			}

			.textBox {
				padding: 10px;
				color: #23a7c5;

				h3 {
					font-weight: bold;
					font-size: 16px;
					margin-bottom: 8px;
				}

				ul {
					li {
						line-height: 1.6;

						&:before {
							content: "";
							width: 5px;
							height: 5px;
							background: #0f9aa2;
							display: inline-block;
							border-radius: 10px;
							vertical-align: middle;
							margin-right: 5px;
						}
					}
				}
			}
		}

		.otherBox {
			display: flex;
			border-top: 2px solid #35dec2;
			border-bottom: 2px solid #35dec2;
			padding: 10px 0;

			.item {
				flex: 1;

				img {
					display: block;
					width: 30px;
					margin: 0 auto 5px;
				}

				p {
					text-align: center;
				}
			}
		}
	}
</style>