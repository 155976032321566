<template>
  <div class="login">
    <div class="head">
      <h1>Login</h1>
      <p>v1003.1.0.0.14</p>
      <div class="logo-container">
      	<img src="@/assets/images/logo.svg" alt="" class="logo" />
      </div>
    </div>
    <div class="content">
      <h2>Login</h2>
      <div class="tips">Please login to access more content</div>
      <div class="textbox">
        <span>Username</span>
        <div class="inputBox">
          <input
            type="text"
            class="inputtext"
            v-model="username"
            placeholder="phone/username"
          />
        </div>
      </div>
      <div class="textbox">
        <span>Password</span>
        <div class="inputBox">
          <input
            type="password"
            v-model="password"
            class="inputtext"
            placeholder="Type Password"
          />
        </div>
      </div>
      <div class="forget" @click="forgetPwd()">Forgot password?</div>
      <div class="df_btn" @click="login()">Login Now</div>
      <div class="regeist_btn" @click="regeist()">Register Now</div>
    </div>
    <van-action-sheet
      v-model="showSheet"
      :actions="actions"
      cancel-text="Cancel"
      close-on-click-action
      @select="selectSheet"
      @cancel="showSheet=false"
    />
  </div>
</template>
  
  <script>
export default {
  props: {},
  data() {
    return {
      showSheet: false,
      actions: [{ name: "Ripemedia CS" }],
      password: "",
      username: "",
    };
  },
  methods: {
    async login() {
      const res = await this.getAjax(
        "api/zopuwmbfapsnb/bbywisnzjawm/login",
        {
          password: this.password,
          username: this.username,
        },
        "post"
      );
      if (res.data) {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        localStorage.setItem("firstLogin", true);
        this.$router.push("/home");
      }
    },
    regeist() {
      this.$router.push("/regeist");
    },
    forgetPwd() {
      this.showSheet = true;
    },
    selectSheet(opt){
      window.open(opt.value,'_blank');
    }
  },
  async created() {
    //获取客服列表
    const res2 = await this.getAjax('api/ahsuoapiemla/bbshwuzma/getCustomer',{},'get');
    this.actions = res2.data.filter(e=>!!e.name);
  },
};
</script>
  <style lang="less" socped>
.login {
  .head {
    width: 100%;
    height: 40vh;
    background: url("@/assets/images/nav_bg.png") no-repeat;
    background-size: cover;
    .logo-container {
    	width: 100%;
    	max-width: 300px;
    	/* 设置logo图的最大宽度 */
    	margin: 70px auto;
    }
    
    @media (max-width: 375px) {
    	.logo-container {
    		margin: 50px auto;
    		max-width: 200px;
    		/* 在小屏幕上设置较小的宽度 */
    	}
    }
    
    .logo {
    	width: 100%;
    	height: auto;
    }
    h1 {
      color: #fff;
      font-size: 18px;
      height: 36px;
      line-height: 34px;
      padding: 0 15px;
    }
    p {
      font-size: 12px;
      color: #97bec3;
      padding: 0 10px;
    }
  }
  .content {
    padding: 15px;
    h2 {
      font-size: 20px;
      color: #27a3c7;
      padding: 15px 0;
    }
    .tips {
      font-size: 14px;
      color: #666;
    }
    .textbox {
      margin-top: 10px;
      border: 2px solid #c1e7ee;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 15px;
      span {
        margin-right: 15px;
        color: #27a3c7;
        font-size: 13px;
      }
      .inputBox {
        flex: 1;
        .inputtext {
          border: 0;
          outline: none;
          text-align: right;
          width: 100%;
        }
      }
    }
    .forget {
      font-size: 20px;
      margin-top: 30px;
      text-align: center;
      text-decoration: underline;
      font-weight: bold;
      color: #27a3c7;
    }
    .df_btn {
      margin: 15px auto;
    }
    .regeist_btn {
      width: 60%;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background-image: linear-gradient(to right, #25abc4, #1acbb5);
      background-size: cover;
      color: #fff;
      margin: 0 auto;
      border-radius: 5px;
      font-size: 14px;
      opacity: 0.4;
    }
  }
}
</style>
  